import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import TermsAndConditionsScreen from './screens/TermsAndConditionsScreen';
import CookiesPolicyScreen from './screens/CookiesPolicyScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';

function App() {
  return (
    <BrowserRouter>
      <div id="main-wrapper">
        <div id="main-container">
          <a href="#section-home" id="back-to-top" className="back-to-top" title="Back to top">
            {' '}
          </a>
        </div>
      </div>
      <main>
        <Routes>
          <Route path="/" element={<NavBar />} />
          <Route path="/terms" element={<TermsAndConditionsScreen />} />
          <Route path="/cookies" element={<CookiesPolicyScreen />} />
          <Route path="/privacy" element={<PrivacyPolicyScreen />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;

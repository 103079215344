import React from 'react';
import ServiceItem from '../components/ServiceItem';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function ServiceScreen() {
  return (
    <div className="main-section " id="section-services">
      <div className="container gx-4">
        <div className="row gx-36">
          <div className="col-lg-8 offset-lg-2">
            <div className="section-title text-center">
              <div className="section-title-container">
                <div className="section-title-body">
                  <div className="section-title-heading">
                    <h6 className="font-weight-500 accent-color">We are good at</h6>
                    <h2>Our services</h2>
                  </div>

                  <div className="hr-divider hr-divider-layout-1 accent-color"></div>

                  <div className="section-title-content">
                    <p>
                      We offer a comprehensive range of professional services to meet the diverse needs of our clients.
                      From web design and development to digital marketing, e-commerce solutions, and beyond, our
                      extensive suite of services ensures that we can provide end-to-end solutions to drive your online
                      success. With a team of experienced experts in each field, we deliver exceptional quality across
                      all aspects of our offerings, tailored specifically to meet your unique business requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="gap gap-36"></div>
          </div>

          <div className="col-lg-4">
            <ServiceItem title={'Web design'} ico={icon({ name: 'wand-magic-sparkles' })} />
            <div className="gap gap-18 d-none d-lg-block"></div>
            <div className="gap gap-18"></div>
            <ServiceItem title={'E-Commerce Website Development'} ico={icon({ name: 'desktop' })} />
            <div className="gap gap-18 d-lg-none"></div>
          </div>
          <div className="col-lg-4">
            <ServiceItem title={'Mobile development'} ico={icon({ name: 'mobile' })} />
            <div className="gap gap-18 d-none d-lg-block"></div>
            <div className="gap gap-18"></div>
            <ServiceItem title={'Portfolio/Blog Website Development'} ico={icon({ name: 'book-open-reader' })} />
            <div className="gap gap-18 d-lg-none"></div>
          </div>
          <div className="col-lg-4">
            <ServiceItem title={'Web Development'} ico={icon({ name: 'code' })} />
            <div className="gap gap-18 d-none d-lg-block"></div>
            <div className="gap gap-18"></div>
            <ServiceItem title={'Graphic Design'} ico={icon({ name: 'tablet-screen-button' })} />
            <div className="gap gap-18 d-lg-none"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

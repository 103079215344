import React from 'react';
export default function PortfolioItem(props) {
  return (
    <div className="grid-item">
      <div className="main-block">
        <div className="main-block-container">
          <div className="main-block-header">
            <a
              href={`${props.image}`}
              className="overlay-effect zoom-effect"
              data-lightbox="gallery-software"
              data-lightbox-caption={`${props.alt}`}
            >
              <img src={`${props.image}`} alt={`${props.alt}`} style={{ width: 400, height: 200 }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

export default function MainPage() {
  return (
    <div
      className="main-section pt-0 pb-0 dark-1-color-bg "
      style={{
        backgroundImage: `url("./assets/images/chicago-panorama.jpg")`,
      }}
      id="section-home"
    >
      <div className="container gx-4">
        <div className="row gx-36 align-items-center justify-content-center height-100vh pt-72 pb-72">
          <div className="col-lg-12">
            <div className="gap gap-72 d-none d-xl-block"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import PortfolioItem from '../components/PortfolioItem';

export default function PortfolioScreen() {
  return (
    <div className="main-section dark-1-color-bg" id="section-portfolio">
      <div className="container gx-4">
        <div className="row gx-36">
          <div className="col-lg-8 offset-lg-2">
            <div className="section-title text-center light-color">
              <div className="section-title-container">
                <div className="section-title-body">
                  <div className="section-title-heading">
                    <h2>Our Latest Work</h2>
                  </div>
                  <div className="hr-divider hr-divider-layout-1 accent-color"></div>
                </div>
              </div>
            </div>

            <div className="gap gap-36"></div>
          </div>

          <div className="col-lg-12">
            <div className="main-block gallery-block grid-wrapper">
              <div className="row gx-3 gy-3 row-cols-lg-4 row-cols-md-2">
                <PortfolioItem image={'./assets/images/portfolio/brilliant.png'} alt="Brilliant" />
                <PortfolioItem image={'./assets/images/portfolio/printsmashcustom.png'} alt="Print Smash" />
                <PortfolioItem image={'./assets/images/portfolio/sniffer-demo.jpg'} alt="Sniffer" />
                <PortfolioItem image={'./assets/images/portfolio/RichieWebsite.png'} alt="Richie Website" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import MainPage from '../screens/MainPage';
import AboutScreen from '../screens/AboutScreen';
import ServiceScreen from '../screens/ServiceScreen';
import ContactScreen from '../screens/ContactScreen';
import Footer from '../screens/Footer';
import PortfolioScreen from '../screens/PortfolioScreen';

export default function NavBar() {
  return (
    <div>
      <header className="active header-section header-section-fixed header-section-transparent header-section-transparent-light">
        <div className="header-section-container">
          <div className="header-menu-section">
            <div className="header-menu-section-container">
              <nav className=" navbar navbar-expand-lg sticky-top">
                <div className="container gx-4">
                  <a className="navbar-brand" href="/" title="KD DZIEKAN">
                    <img src="assets/images/logo/logo-no-background.png" alt="KD DZIEKAN" className="normal" />
                    <img src="assets/images/logo/logo-no-background.png" alt="KD DZIEKAN" className="transparent" />
                  </a>

                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#mobile-menu"
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-wd">MENU</span>
                    <span className="navbar-toggler-lines-wrapper">
                      <i className="navbar-toggler-line navbar-toggler-line-top"></i>
                      <i className="navbar-toggler-line navbar-toggler-line-middle"></i>
                      <i className="navbar-toggler-line navbar-toggler-line-bottom"></i>
                    </span>
                  </button>

                  <div className="header-menu desktop-menu navbar-collapse" id="mobile-menu">
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a className="nav-link" href="#section-home">
                          HOME
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link" href="#section-about">
                          ABOUT
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link" href="#section-services">
                          SERVICES
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link " href="#section-portfolio">
                          <span>PORTFOLIO</span>
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link" href="#section-contact-us">
                          CONTACT US
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div>
        <MainPage />
        <AboutScreen />
        <ServiceScreen />
        <PortfolioScreen />
        <ContactScreen />
        <Footer />
      </div>
    </div>
  );
}

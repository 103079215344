import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ServiceItem(props) {
  return (
    <div className="main-block icon-block icon-block-layout-2">
      <div className="main-block-container icon-block-container">
        <div className="main-block-header icon-block-header accent-color">
          <FontAwesomeIcon icon={props.ico} size="2xl" style={{ color: '#2E784A' }} />
        </div>
        <div className="main-block-body icon-block-body">
          <div className="main-block-heading icon-block-heading">
            <h4>{props.title}</h4>
          </div>
          <div className="main-block-content icon-block-content">
            <p>{props.descryption}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

export default function AboutScreen() {
  return (
    <div className="main-section" id="section-about">
      <div className="container gx-4">
        <div className="row gx-36 flex-row-reverse align-items-center">
          <div className="col-lg-6">
            <div>
              <div className="owl-slider-item">
                <div className="main-block image-block">
                  <div className="main-block-container image-block-container">
                    <div className="main-block-header image-block-header">
                      <img src="assets/images/ese.png" alt="KD Dziekan" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="section-title">
              <div className="section-title-container">
                <div className="section-title-body">
                  <div className="section-title-heading">
                    <h4>Who are we?</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-block text-block">
              <div className="main-block-container text-block-container">
                <div className="main-block-body text-block-body">
                  <div className="main-block-content text-block-content">
                    <p>
                      At KD Dziekan, we believe that a well-designed website is the cornerstone of a successful online
                      business. We work closely with our clients to understand their unique goals and requirements,
                      crafting tailored solutions that effectively engage their target audience and drive measurable
                      results. Whether it's a responsive e-commerce platform, a content management system, or a custom
                      web application, our team leverages the latest technologies and industry best practices to deliver
                      exceptional digital experiences.
                    </p>

                    <p>
                      Our expertise extends beyond web development to encompass a comprehensive suite of services,
                      including UI/UX design, mobile app development, search engine optimization (SEO), and digital
                      marketing. By combining technical prowess with a deep understanding of user behavior and market
                      trends, we deliver solutions that not only look great but also perform optimally across various
                      devices and platforms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

// eslint-disable-next-line
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}\s*$/;

export default function ContactScreen() {
  const [status, setStatus] = useState(undefined);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const handleError = (errors) => {};

  const onSubmit = (formData) => {
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          // console.log(result.text);
          setStatus({ type: 'success' });
        },
        (error) => {
          // console.log(error.text);
          setStatus({ type: 'error', error });
        }
      );
    reset();
  };

  const registerOptions = {
    name: {
      required: 'Name is required',
      minLength: {
        value: 3,
        message: 'Name should be at least 3 characters long',
      },
      maxLength: {
        value: 24,
        message: 'Name should be max 24 characters long',
      },
    },
    email: { required: 'Email is required', pattern: { value: EMAIL_REGEX, message: 'Email is invalid' } },
    subject: {
      required: 'Subject is required',
      minLength: {
        value: 4,
        message: 'Subject should be at least 4 characters long',
      },
      maxLength: {
        value: 100,
        message: 'Subject should be max 100 characters long',
      },
    },
    message: {
      required: 'Message is required',
      minLength: {
        value: 10,
        message: 'Message should be at least 10 characters long',
      },
      maxLength: {
        value: 600,
        message: 'Name should be max 600 characters long',
      },
    },
    terms: { required: 'You must agree before submitting' },
  };

  return (
    <div className="main-section" id="section-contact-us">
      <div className="container gx-4">
        <div className="row gx-36">
          <div className="col-lg-8 offset-lg-2">
            <div className="section-title text-center ">
              <div className="section-title-container">
                <div className="section-title-body">
                  <div className="section-title-heading">
                    <h2>Contact Us</h2>
                  </div>
                  <div className="hr-divider hr-divider-layout-1 accent-color"></div>
                  <div className="section-title-content">
                    <p>
                      Contact us today for a complimentary consultation and a detailed estimate tailored to your
                      specific project requirements. Our team of experts is ready to discuss your needs, provide
                      insights, and offer recommendations to help you make informed decisions about your upcoming
                      project. Get in touch with us now to take the first step towards bringing your vision to life.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="gap gap-36"></div>
          </div>
          <div className="col-lg-8">
            <div className="form-block  form-block-contact">
              <div className="form-block-container">
                <form onSubmit={handleSubmit(onSubmit, handleError)}>
                  <div className="col-md-6">
                    <label className="label">
                      Name <span className="red-color"> *</span>
                    </label>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      {...register('name', registerOptions.name)}
                    />
                    <small style={{ color: 'red', alignSelf: 'stretch' }}>{errors?.name && errors.name.message}</small>
                  </div>
                  <div className="col-md-6">
                    <label className="label">
                      Email <span className="red-color"> *</span>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      {...register('email', registerOptions.email)}
                    />
                    <small style={{ color: 'red', alignSelf: 'stretch' }}>
                      {errors?.email && errors.email.message}
                    </small>
                  </div>
                  <div className="col-lg-12">
                    <label className="label">
                      Subject <span className="red-color"> *</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="subject"
                      {...register('subject', registerOptions.subject)}
                    />
                    <small style={{ color: 'red', alignSelf: 'stretch' }}>
                      {errors?.subject && errors.subject.message}
                    </small>
                  </div>
                  <div className="col-lg-12">
                    <label className="label">
                      Message <span className="red-color"> *</span>
                    </label>
                    <textarea
                      rows="8"
                      className="form-control"
                      type="text"
                      name="message"
                      {...register('message', registerOptions.message)}
                    ></textarea>
                    <small style={{ color: 'red', alignSelf: 'stretch' }}>
                      {errors?.message && errors.message.message}
                    </small>
                  </div>
                  <div className="col-lg-12">
                    <label className="label input-type-checkbox" htmlFor="terms">
                      <input
                        className="form-control form-check-must-accept"
                        type="checkbox"
                        name="terms"
                        {...register('terms', registerOptions.terms)}
                      />{' '}
                      I have read and agree to KD Dziekan{' '}
                      <Link to="/terms" title="terms and conditions">
                        Terms & Conditions
                      </Link>
                      .
                    </label>
                    <small style={{ color: 'red', alignSelf: 'stretch' }}>
                      {errors?.terms && errors.terms.message}
                    </small>
                  </div>

                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-accent btn-block">
                      <span>SEND</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="row gx-36">
              <div className="col-lg-12">
                <div className="main-block contact-block boxed-block white-color-bg grey-2-color-border">
                  <div className="main-block-container contact-block-container">
                    <div className="main-block-body contact-block-body">
                      <div className="main-block-content contact-block-content">
                        <h6>Email:</h6>
                        <p>
                          Support: <a href="mailto:support@kddziekan.com">support@kddziekan.com</a>
                          <br />
                          Sales: <a href="mailto:sales@kddziekan.com">sales@kddziekan.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {status !== undefined ? (
                  status !== 'success' ? (
                    <div className="main-block contact-block boxed-block white-color-bg grey-2-color-border">
                      <div className="main-block-container contact-block-container">
                        <div className="main-block-body contact-block-body">
                          <div className="main-block-content contact-block-content">
                            <h6>Success</h6>
                            <p>
                              Someone from the team will contact you shortly.
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="main-block contact-block boxed-block white-color-bg grey-2-color-border">
                      <div className="main-block-container contact-block-container">
                        <div className="main-block-body contact-block-body">
                          <div className="main-block-content contact-block-content">
                            <h6>Upssss !</h6>
                            <p>
                              Something went wrong !
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

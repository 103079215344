import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="footer-section-copyright">
      <div className="main-section">
        <div className="container gx-4">
          <div className="row gx-36 align-items-center">
            <div className="col-lg-6">
              <div className="footer-section-copyright-content light-color text-center text-lg-start">
                <p>
                  &copy; <span className="current-year">{year}</span> Designed &amp; Developed by{' '}
                  <a href="/" title="KD Dziekan">
                    KD Dziekan
                  </a>
                  , All rights reserved.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="footer-section-copyright-content light-color text-center text-lg-end">
                <nav className="nav-menu-block">
                  <ul>
                    <li>
                      <Link to="/privacy" title="Privacy Policy">
                        <span>Privacy Policy</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms" title="Terms & Conditions">
                        <span>Terms & Conditions</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/cookies" title="Cookies Policy">
                        <span>Cookies Policy</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
